// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

@import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
@import '../../node_modules/owl.carousel/';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// mixin scss
@import "mixins";

// Custom Style
@import 'custom';

// Comingsoon Style
@import "coming_soon";

// Icons
$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import '~material-design-icons-iconfont/src/material-design-icons';
