@import url('https://fonts.googleapis.com/css2?family=El+Messiri&display=swap');

.bg {
    background: url("../../public/images/bgcomingsoon.png") no-repeat center center fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: fixed;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.item {
    color: white;
}



.coming-title {
    font-family: 'El Messiri', sans-serif;
    font-size: 4rem;
}

@media (min-width: 840px) {

    .coming-title {
        font-size: 7rem;
    }
}