.brand {
    height: auto;
    width: 120px;
}

.mobile-menu {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin-right: 3%;
    color: #2e3440;
}

.carousel-sub-text {
    color: #0d6efd;
    font-weight: bold;
}

.phone-contact a {
    color: #2c3e50;
}


.navbar-text {
    font-size: 1rem !important;
    font-weight: bold;
}

.sidenav-text a {
    color: #2e3440 !important;
}

.nav-item,
.nav-active a {
    color: #398AB9 !important;
}

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 999;
    background: #ffff;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#sidebar.active {
    left: 0;
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.overlay.active {
    display: block;
    opacity: 1;
}


.overlay-carousel::before {
    background-color: rgba(0, 0, 0, 0.37);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
}

.gmaps-container {
    width: auto;
}

.gmaps-iframe {
    display: block;
    width: 100%;
    height: 300px;
}


// Login page

.login-page {
    background: url('../../public/images/carousel2-edited.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

.btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
}

.btn-google {
    color: white !important;
    background-color: #ea4335;
}

.btn-facebook {
    color: white !important;
    background-color: #3b5998;
}

.register-text {
    color: #4c566a;
}

.register-text-create {
    font-weight: bold;
}

.btn-prev-page {
    color: #5e81ac;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.article-like {
    margin-left: 20px;
}


@media(min-width: 50px) {}

// Media min width 360

@media(min-width: 200px) {
    .carousel-text {
        font-size: 0.7rem;
    }

    .carousel-subtext {
        font-size: 0.3rem;
    }

    .lead {
        margin-bottom: 1rem !important;
    }

}


@media(min-width: 360px) {
    .carousel-text {
        font-size: 0.9rem;
    }

    .carousel-subtext {
        font-size: 0.5rem;
    }

    .lead {
        margin-bottom: 1.5rem !important;
    }

}


@media(min-width: 440px) {
    .carousel-text {
        font-size: 1.1rem;
    }

    .carousel-subtext {
        font-size: 0.5rem;
    }

}


// Media minw idth 480

@media(min-width: 480px) {
    @media(min-width: 360px) {
        .carousel-text {
            font-size: 1.3rem;
        }

        .carousel-subtext {
            font-size: 0.6rem;
        }

    }
}

@media(min-width: 600px) {
    .btn {
        font-size: 1rem;
    }
}

// Media min width 768px

@media (min-width: 768px) {

    .mobile-menu {
        visibility: hidden;
    }
}


@media(min-width: 800px) {

    .btn-google {
        font-size: 0.9rem;
    }

    .btn-facebook {
        font-size: 0.9rem;
    }
}

@media (min-width: 840px) {

    .carousel-text {
        font-size: 1.5rem;
    }

    .carousel-subtext {
        font-size: 0.7rem;
    }
}

@media (min-width: 1044px) {

    .carousel-text {
        font-size: 1.7rem;
    }

    .carousel-subtext {
        font-size: 0.8rem;
    }
}
